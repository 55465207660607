import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorCode } from "../../../api/errorCodes";

const GeneralErrorContext = createContext({});

export const GeneralErrorProvider = ({ children }) => {
    const navigate = useNavigate();
    const [showGeneralError, setShowGeneralError] = useState(false);
    const [message, setMessage] = useState("");

    function handleUnauthorizedError(error) {
        console.log("Unauthorized");
        navigate("/unauthorized");
    }

    function handleForbiddenError(error) {
        if (error.response.data.errorCode === ErrorCode.IMAGE_NO_CODE) {
            window.open("/admin/dropboxauth", "_blank");
            return;
        }
        console.log("Forbidden");
        navigate("/unauthorized");
    }

    async function handleGeneralError(error) {
        if (!error.response) {
            setMessage("No Server Response");
            console.log("No Server Response");
            setShowGeneralError(true);
        } else if (error.response?.status === 401) {
            handleUnauthorizedError(error);
        } else if (error.response.status === 403) {
            handleForbiddenError(error);
        } else {
            const parsedError = JSON.parse(await error.response.data.text());
            setMessage(parsedError.message);
            console.log(message);
            setShowGeneralError(true);
        }
    }

    return (
        <GeneralErrorContext.Provider
            value={{
                showGeneralError,
                setShowGeneralError,
                handleGeneralError,
                message,
            }}
        >
            {children}
        </GeneralErrorContext.Provider>
    );
};

export default GeneralErrorContext;
